<template>
  <v-col md="8" class="mx-auto pa-0">
    <div class="pa-4">
      <v-btn rounded text class="mx-1" @click="$router.go(-1)" fab
        ><v-icon small>fal fa-times</v-icon></v-btn
      >
    </div>
    <div class="parent">
      <v-card class="child" md="12">
        <v-card class="pa-2">
          <p class="subtitle-1">
            Please enter the application number shared over SMS, Mail or
            WhatsApp, if you don't remember your application ID, please contact
            customer support.
          </p>
          <v-text-field
            v-model="applicationNumber"
            label="Application Number"
            required
          ></v-text-field>
          <v-btn color="primary" class="mr-4" @click="checkApplication" right>
            Check Application
          </v-btn>
        </v-card>
      </v-card>
    </div>
  </v-col>
</template>

<script>
export default {
  data() {
    return {
      applicationNumber: null
    };
  },
  created() {
    window.scrollTo(0, 0);
  },
  methods: {
    checkApplication() {
      this.$router.push("/finance/success/" + this.applicationNumber);
    }
  }
};
</script>

<style>
.parent {
  min-height: 100vh;
  position: relative;
  overflow: hidden;
}
.child {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
}
</style>
